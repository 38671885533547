import { useCallback, useContext, useMemo } from 'react';
import set from 'lodash/set';
import debounce from 'lodash/debounce';
import { BaseSettings, DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import Layer from 'components/template-designer/types/layer.type';
import cloneDeep from 'helpers/cloneDeep';
import { DynamicLayersContext } from './dynamic-layers.context';

export type DynamicLayersHookType<SettingsType = BaseSettings> = {
    activeInput: DynamicLayerInput | null;
    setActiveInput: React.Dispatch<React.SetStateAction<DynamicLayerInput | null>>;
    activeInputLayer: Layer | null;
    updateActiveInput: (setting: string, value: unknown, options?: { useDebounce?: boolean }) => void;
    updateInputSettings: <K extends keyof SettingsType>(setting: K, value: SettingsType[K], options?: { useDebounce?: boolean }) => void;
    settings: SettingsType;
};

/**
 * Hook to use the dynamic layers context.
 * @param settings - The settings to get from the active input.
 */
const useDynamicLayers = <SettingsType = BaseSettings>(settingsToGet?: string[]): DynamicLayersHookType<SettingsType> => {
    const context = useContext(DynamicLayersContext);

    /**
     * Throw an error if the hook is used outside of the provider.
     */
    if (context === undefined) {
        throw new Error('useDynamicLayers must be used within a DynamicLayersProvider');
    }

    const { activeInput, setActiveInput, activeInputLayer } = context;

    /**
     * On change event.
     * @param setting - The setting to update.
     * @param value - The value of the setting.
     */
    const updateInputSettings = <K extends keyof SettingsType>(setting: K, value: SettingsType[K], options?: { useDebounce?: boolean }): void => {
        if (!activeInput) return;

        const { useDebounce = false } = options || {};

        setActiveInput((prev) => {
            if (!prev) return prev;
            return {
                ...prev,
                settings: {
                    ...prev.settings,
                    [setting]: value
                }
            } as DynamicLayerInput;
        });

        if (useDebounce) {
            debouncedUpdateStore(`settings.${String(setting)}`, value);
        } else {
            updateStore(`settings.${String(setting)}`, value);
        }
    };

    /**
     * On change event.
     * @param setting - The setting to update.
     * @param value - The value of the setting.
     */
    const updateActiveInput = (model: string, value: unknown, options?: { useDebounce?: boolean }): void => {
        if (!activeInput) return;

        const { useDebounce = false } = options || {};

        setActiveInput((prev) => {
            if (!prev) return prev;
            const clonedPrev = cloneDeep(prev);
            return set(clonedPrev, model, value) as DynamicLayerInput;
        });

        if (useDebounce) {
            debouncedUpdateStore(model, value);
        } else {
            updateStore(model, value);
        }
    };

    /**
     * Update the store.
     * @param model - The model to update.
     * @param value - The value of the setting.
     */
    const updateStore = (model: string, value: unknown) => {
        if (!activeInput) return;
        DynamicLayerHelpers.updateInput(activeInput, model, value);
    };

    /**
     * Update the store with a debounce.
     * @param model - The model to update.
     * @param value - The value of the setting.
     */
    const debouncedUpdateStore = useCallback(
        debounce((model: string, value: unknown) => {
            if (!activeInput) return;
            DynamicLayerHelpers.updateInput(activeInput, model, value);
        }, 500),
        [activeInput?.key]
    );

    /**
     * Get the settings from the active input.
     */
    const settings: SettingsType = useMemo(() => {
        if (!activeInput) return {} as SettingsType;
        if (!settingsToGet) return activeInput.settings as SettingsType;

        return settingsToGet.reduce((acc, setting) => {
            acc[setting] = activeInput.settings[setting];
            return acc;
        }, {} as SettingsType);
    }, [activeInput, settingsToGet, context]);

    return {
        activeInput,
        setActiveInput,
        activeInputLayer,
        updateActiveInput,
        updateInputSettings,
        settings
    };
};

export { useDynamicLayers };
