import React from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { SwitchSettings } from 'components/template-designer/types/dynamicLayer.type';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/main.scss';

interface Props {
    label: string;
    tooltip?: string;
    setting: string;
    inverted?: boolean;
}

const SwitchSettingsForm = ({ label, tooltip, setting, inverted = false }: Props): JSX.Element | null => {
    const { settings, activeInput, updateInputSettings } = useDynamicLayers<SwitchSettings>();
    if (!activeInput || settings.type !== 'switch') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            <SwitchSetting
                label={label}
                tooltip={tooltip}
                value={settings[setting] ?? false}
                inverted={inverted}
                update={(value) => updateInputSettings(setting as keyof SwitchSettings, value)}
            />
        </div>
    );
};

export { SwitchSettingsForm };
