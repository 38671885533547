import React from 'react';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/dynamic-layers.context';
import { AlertSettings } from 'components/template-designer/types/dynamicLayer.type';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import '../styles/main.scss';

const AlertSettingsForm = (): JSX.Element | null => {
    const { settings, activeInput, updateInputSettings } = useDynamicLayers<AlertSettings>();

    if (!activeInput) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            <FieldSetCopy
                label={Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.message', 'template-designer')}
                value={settings.message}
                useValueObject={false}
                onMutation={(value) => updateInputSettings('message', value, { useDebounce: true })}
            />
            <FieldSetSelectInput
                size="small"
                label={Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertType', 'template-designer')}
                value={settings.alertType}
                options={{
                    info: Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.info', 'template-designer'),
                    alert: Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.warning', 'template-designer'),
                    success: Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.success', 'template-designer'),
                    error: Translation.get('dynamicLayers.layerEdit.editForms.forms.alertSettings.alertOptions.error', 'template-designer')
                }}
                onMutation={(newValue) => updateInputSettings('alertType', newValue, { useDebounce: false })}
            />
        </div>
    );
};

export { AlertSettingsForm };
