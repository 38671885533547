import React, { useEffect } from 'react';
import '../styles/collection-setting.scss';
import { CollectionTreeChild } from 'types/collection.type';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import TextField from 'components/ui-components-v2/TextField';
import Translation from 'components/data/Translation';
import { FieldSet } from 'components/input/FieldSet';

interface Props {
    value?: string;
    onMutation: (newValue: string) => void;
}

const CollectionSetting = ({ value, onMutation }: Props) => {
    const [titles, setTitles] = React.useState<(string | null)[]>([]);

    useEffect(() => {
        AssetManagementService.getCollectionsTree({
            type: 'media'
        }).then((response) => {
            const titles: (string | null)[] = [];

            const getTitles = (collection: CollectionTreeChild) => {
                titles.push(collection.title);

                if (collection.children) {
                    collection.children.forEach((child) => {
                        getTitles(child);
                    });
                }
            };

            response.structure.forEach((collection) => {
                getTitles(collection);
            });

            // alphabetically sort the titles and add a null value to the beginning to allow for no collection to be selected
            titles.sort();
            titles.unshift(null);

            setTitles(titles);
        });
    }, []);

    const onChange = (newValue: string) => {
        onMutation(newValue);
    };

    return (
        <FieldSet
            fullWidth
            label={Translation.get(
                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionName',
                'template-designer'
            )}
            tooltip={Translation.get(
                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionNameTooltip',
                'template-designer'
            )}>
            <Autocomplete
                value={value}
                options={titles}
                onChange={(event, newValue) => {
                    onChange(newValue);
                }}
                getOptionLabel={(option) => {
                    if (option) {
                        return option;
                    }

                    return Translation.get(
                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.deselectCollection',
                        'template-designer'
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.searchCollection',
                            'template-designer'
                        )}
                    />
                )}
                className="template-designer__dynamic-layer-edit__collection-setting"
            />
        </FieldSet>
    );
};

export { CollectionSetting };
