import React, { useEffect, useState } from 'react';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings } from 'components/template-designer/types/dynamicLayer.type';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { ExpandSection } from 'components/template-designer/components/ui-components/expand-section';
import { PopupPanel } from 'components/template-designer/components/ui-components/popup-panel';
import SelectInput from 'components/input/SelectInput';
import { FieldSetCopy } from 'components/input/Copy';
import { FeatureHelpers } from 'components/template-designer/helpers/features.helpers';
import { Features } from 'components/template-designer/types/features.type';
import User from 'components/data/User';
import Setup from 'components/data/Setup';
import { SwitchSetting } from '../inputs/switch-setting';
import { CheckboxSetting } from '../inputs/checkbox-setting';
import { ExtraSettingsButton } from '../inputs/extra-settings-button';
import { CollectionSetting } from '../inputs/collection-setting';
import '../styles/main.scss';

enum PopUpPanels {
    AssetLibrary = 'assetLibrary',
    Cropper = 'cropper'
}

const VideoSettingsForm = (): JSX.Element | null => {
    const [popUpOpen, setPopUpOpen] = useState<PopUpPanels | null>(null);

    const { settings, activeInput, activeInputLayer, updateInputSettings } = useDynamicLayers<AssetGalleryInputSettings>();

    /**
     * Close the popup if the active input changes.
     */
    useEffect(() => {
        if (activeInput && activeInputLayer) return;
        setPopUpOpen(null);
    }, [activeInput, activeInputLayer]);

    if (!activeInput || !activeInputLayer) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__media-settings-form">
            <ExpandSection
                expandSectionKey="inputSources"
                spacing="medium"
                disabled
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.title', 'template-designer')}
                defaultOpen>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.freeUpload', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.freeUploadVideoTooltip', 'template-designer')}
                    icon="upload"
                    value={settings.canUpload}
                    update={(newValue) => updateInputSettings('canUpload', newValue)}
                />
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrary', 'template-designer')}
                    tooltip={Translation.get(
                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibraryVideoTooltip',
                        'template-designer'
                    )}
                    icon="folder_open"
                    value={settings.canUseContentSpace}
                    update={(newValue) => updateInputSettings('canUseContentSpace', newValue)}>
                    <PopupPanel
                        width={350}
                        open={popUpOpen === PopUpPanels.AssetLibrary}
                        placement="bottom-end"
                        button={<ExtraSettingsButton onClick={() => setPopUpOpen(PopUpPanels.AssetLibrary)} disabled={!settings.canUseContentSpace} />}>
                        {Setup.hasModule('mediaManagement') &&
                            User.hasRight(['assetManagerMediaWrite', 'assetManagerMediaRead', 'assetManagerMediaManagement']) && (
                                <CollectionSetting
                                    value={settings.contentSpaceCollectionQuery}
                                    onMutation={(newValue) => updateInputSettings('contentSpaceCollectionQuery', newValue, { useDebounce: true })}
                                />
                            )}
                        {Setup.hasModule('assetLibrary') && (
                            <FieldSetCopy
                                size="small"
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionName',
                                    'template-designer'
                                )}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionNameTooltip',
                                    'template-designer'
                                )}
                                useValueObject={false}
                                value={settings.contentSpaceCollectionQuery}
                                onMutation={(newValue) => updateInputSettings('contentSpaceCollectionQuery', newValue, { useDebounce: true })}
                            />
                        )}
                    </PopupPanel>
                </SwitchSetting>
                {FeatureHelpers.hasFeature(Features.URL_SELECTOR) && (
                    <SwitchSetting
                        label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.url', 'template-designer')}
                        tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.urlVideoTooltip', 'template-designer')}
                        icon="link"
                        value={settings.canUseUrl}
                        update={(newValue) => updateInputSettings('canUseUrl', newValue)}
                    />
                )}
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.imageToVideo', 'template-designer')}
                    icon="auto_awesome"
                    value={settings.canUseImageToVideo}
                    update={(newValue) => updateInputSettings('canUseImageToVideo', newValue)}
                    switchColor="secondary"
                />
            </ExpandSection>

            <ExpandSection
                expandSectionKey="mediaManipulation"
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.videoTitle', 'template-designer')}
                spacing="medium"
                disabled
                defaultOpen>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.videoCropper', 'template-designer')}
                    icon="crop"
                    value={settings.useCropper}
                    update={(newValue) => updateInputSettings('useCropper', newValue)}>
                    <PopupPanel
                        width={350}
                        open={popUpOpen === PopUpPanels.Cropper}
                        placement="bottom-end"
                        title={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.title',
                            'template-designer'
                        )}
                        button={<ExtraSettingsButton onClick={() => setPopUpOpen(PopUpPanels.Cropper)} disabled={!settings.useCropper} />}>
                        <SelectInput
                            options={{
                                sizeBased: Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.free',
                                    'template-designer'
                                ),
                                ratioBased: Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.fixed',
                                    'template-designer'
                                )
                            }}
                            value={settings.cropMode}
                            onMutation={(newValue) => updateInputSettings('cropMode', newValue)}
                        />
                        {settings.cropMode === 'sizeBased' && (
                            <>
                                <div className="template-designer__dynamic-layer-edit__media-settings-form__setting">
                                    <FieldSetCopy
                                        size="small"
                                        label={Translation.get('sizes.width', 'common')}
                                        value={settings.outputWidth}
                                        useValueObject={false}
                                        onMutation={(value) =>
                                            updateInputSettings(
                                                'outputWidth',
                                                (() => {
                                                    const newValue = parseInt(value);
                                                    if (isNaN(newValue)) return 0;
                                                    return value;
                                                })(),
                                                { useDebounce: true }
                                            )
                                        }
                                    />
                                    <FieldSetCopy
                                        size="small"
                                        label={Translation.get('sizes.height', 'common')}
                                        value={settings.outputHeight}
                                        useValueObject={false}
                                        onMutation={(value) =>
                                            updateInputSettings(
                                                'outputHeight',
                                                (() => {
                                                    const newValue = parseInt(value);
                                                    if (isNaN(newValue)) return 0;
                                                    return value;
                                                })(),
                                                { useDebounce: true }
                                            )
                                        }
                                    />
                                </div>
                            </>
                        )}
                        <CheckboxSetting
                            label={Translation.get(
                                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.croppingRequired',
                                'template-designer'
                            )}
                            tooltip={Translation.get(
                                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.croppingRequiredTooltip',
                                'template-designer'
                            )}
                            setting="canSkipCropper"
                            value={!settings.canSkipCropper}
                            update={(event) => updateInputSettings('canSkipCropper', !event.target.checked)}
                        />
                    </PopupPanel>
                </SwitchSetting>
            </ExpandSection>

            <ExpandSection
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.title', 'template-designer')}
                expandSectionKey="outputSettings"
                spacing="medium"
                disabled
                defaultOpen>
                <FieldSetCopy
                    size="small"
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.minimumDuration', 'template-designer')}
                    value={settings.miniumDuration}
                    useValueObject={false}
                    onMutation={(value) =>
                        updateInputSettings(
                            'miniumDuration',
                            (() => {
                                const newValue = parseInt(value);
                                if (isNaN(newValue)) return 0;
                                return value;
                            })(),
                            { useDebounce: true }
                        )
                    }
                />
                <FieldSetCopy
                    size="small"
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.maximumDuration', 'template-designer')}
                    value={settings.maximumDuration}
                    useValueObject={false}
                    onMutation={(value) =>
                        updateInputSettings(
                            'maximumDuration',
                            (() => {
                                const newValue = parseInt(value);
                                if (isNaN(newValue)) return 0;
                                return value;
                            })(),
                            { useDebounce: true }
                        )
                    }
                />
            </ExpandSection>
        </div>
    );
};

export { VideoSettingsForm };
