import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/checkbox-setting.scss';

interface Props {
    label: string;
    setting: string;
    value: boolean | undefined;
    update: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tooltip?: string;
}

const CheckboxSetting = ({ label, setting, value, update, tooltip }: Props) => {
    return (
        <div className="template-designer__dynamic-layer-edit__checkbox-setting">
            <Checkbox size="small" checked={value} onChange={update} color="primary" id={setting} />
            <label className="template-designer__dynamic-layer-edit__checkbox-setting__label" htmlFor={setting}>
                {label}
            </label>
            {tooltip && (
                <Tooltip title={tooltip} disableInteractive>
                    <Icon className="template-designer__dynamic-layer-edit__checkbox-setting__tooltip">info</Icon>
                </Tooltip>
            )}
        </div>
    );
};

export { CheckboxSetting };
