import React from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/dynamic-layers.context';
import { TextSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import cloneDeep from 'helpers/cloneDeep';
import { FieldSetNumber } from 'components/input/Number';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/main.scss';

interface Props {
    defaultCharacterLimit: number;
}

const TextSettingsForm = ({ defaultCharacterLimit }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<TextSettings>();

    if (!activeInput || !activeInputLayer || (settings.type !== 'text' && settings.type !== 'textMultiLanguage')) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form template-designer__dynamic-layer-edit__text-settings-form">
            <SwitchSetting
                label={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.multiline', 'template-designer')}
                value={!!settings['multiline']}
                update={(value) => {
                    updateInputSettings('multiline', value);
                }}
            />
            <SwitchSetting
                label={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.useCharacterLimit', 'template-designer')}
                value={!!settings['maxLength']}
                update={(value) => {
                    if (value) {
                        updateInputSettings('maxLength', defaultCharacterLimit);
                    } else {
                        const newSettings = cloneDeep(settings);
                        delete newSettings.maxLength;
                        updateActiveInput('settings', {
                            ...newSettings
                        });
                    }
                }}
            />
            {!!settings['maxLength'] && (
                <FieldSetNumber
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.characterLimit', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.characterLimitTooltip', 'template-designer')}
                    value={settings.maxLength}
                    min={1}
                    max={2000}
                    onMutation={(newValue) => {
                        updateInputSettings('maxLength', parseInt(newValue));
                    }}
                />
            )}
        </div>
    );
};

export default TextSettingsForm;
