import React, { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import { DynamicLayerInput, FeedItemSelectorSettings } from 'components/template-designer/types/dynamicLayer.type';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import Translation from 'components/data/Translation';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import { FieldSetSwitch } from 'components/input/Switch';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Template, { CurrentView, View } from 'components/template-designer/types/template.type';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { FeedMappingHelpers } from 'components/template-designer/helpers/feed-mapping.helpers';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import Toggle from 'components/template-designer/components/ui-components/toggle';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import TextField from 'components/ui-components-v2/TextField';
import { FieldSet } from 'components/input/FieldSet';
import { DynamicLayerDialog } from '../../../../dynamic-layer-dialog';
import '../styles/feed-item-selector-settings-form.scss';
import '../styles/main.scss';

type Usage = 'custom' | 'locked';

interface Props {
    label: string;
    action: string;
    onClick: () => void;
}

interface FeedTitleProps {
    feedTitle: FeedItemSelectorSettings['feedTitle'];
    onChangeFeedTitle: (value: FeedItemSelectorSettings['feedTitle']) => void;
}

const Warning = ({ label, action, onClick }: Props) => (
    <div className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__warning">
        <div className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__warning-label">
            <Icon className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__warning-label-icon">warning</Icon>
            <span>{label}</span>
        </div>
        <span className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__warning-action" onClick={onClick}>
            {action}
        </span>
    </div>
);

const FeedTitle = ({ feedTitle, onChangeFeedTitle }: FeedTitleProps) => {
    const [feedOptions, setFeedOptions] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>(feedTitle ?? '');

    /**
     * Fetches feed options based on the input value.
     */
    const getFeedOptions = useCallback(
        debounce(async (value: string) => {
            const feeds = await FeedHelpers.getAllFeeds(null, value);
            const feedTitles = feeds.map((feed) => feed.customData.title);
            setFeedOptions(feedTitles);
        }, 300),
        []
    );

    return (
        <FieldSet label={Translation.get('dynamicLayers.labels.feedTitle', 'template-designer')} fullWidth>
            <Autocomplete
                getOptionLabel={(option) => option}
                filterOptions={(x) => x}
                options={feedOptions}
                fullWidth
                value={feedTitle}
                size="small"
                noOptionsText={Translation.get('dynamicLayers.layerEdit.editForms.forms.feedItemSelectorSettings.noFeedTitles', 'template-designer')}
                onChange={(_, newValue: string) => onChangeFeedTitle(newValue)}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                    getFeedOptions(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} value={inputValue} fullWidth />}
                renderOption={(props, option) => {
                    return <li {...props}>{option}</li>;
                }}
            />
        </FieldSet>
    );
};

const FeedItemSelectorSettingsForm = (): JSX.Element | null => {
    const { dynamicLayers, feedMapping, frameType } = useComponentStore<{
        dynamicLayers: Template['dynamicLayers'];
        feedMapping: Template['dataVariables'];
        frameType: View['frameType'];
    }>('TemplateDesigner', {
        fields: {
            dynamicLayers: 'dynamicLayers',
            feedMapping: 'dataVariables',
            frameType: 'view.frameType'
        }
    });

    const { settings, activeInput, setActiveInput, updateInputSettings } = useDynamicLayers<FeedItemSelectorSettings>();

    const [showDynamicLayerDialog, setShowDynamicLayerDialog] = useState<boolean>(false);
    const [usage, setUsage] = useState<Usage>('custom');

    const currentDynamicLayers = dynamicLayers[frameType];
    const currentFeedMapping = feedMapping[frameType];

    /**
     * Sets correct state and set every mapped input to read only when the value is locked.
     * @param value - Usage value from dropdown.
     */
    const handleChangeUsage = (value: Usage) => {
        if (value === 'locked') {
            const dynamicLayersCopy = cloneDeep(currentDynamicLayers);
            const newDynamicLayers = setReadOnly(dynamicLayersCopy);
            TemplateDesignerStore.save([`dynamicLayers.${frameType}`, newDynamicLayers]);
        }

        setUsage(value);
    };

    /**
     * Sets every mapped input to read only.
     * @param inputs - Dynamic layer inputs.
     * @returns Returns the updated dynamic layer inputs.
     */
    const setReadOnly = (inputs: DynamicLayerInput[]) => {
        inputs.forEach((input) => {
            if (input.type === 'feedSelectorInput') {
                input.settings.usage = 'locked';
                return;
            }

            const isInputLinked = 'layerKey' in input && FeedMappingHelpers.isLinkedToInput(currentFeedMapping, input.layerKey, input.attribute);

            if (isInputLinked) {
                input.settings.readOnly = true;
            }

            if ('children' in input) {
                setReadOnly(input.children);
            }
        });

        return inputs;
    };

    const connectedDynamicLayerInputs = useMemo(() => DynamicLayerHelpers.getLinkedInputs(frameType), [dynamicLayers, feedMapping, frameType]);

    if (!activeInput) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form template-designer__dynamic-layer-edit__feed-item-selector-settings-form">
            {showDynamicLayerDialog && (
                <DynamicLayerDialog
                    onClose={() => setShowDynamicLayerDialog(false)}
                    onSubmit={(selected) => {
                        setShowDynamicLayerDialog(false);
                        const inputs = DynamicLayerHelpers.addInputField(selected, activeInput);
                        setActiveInput(inputs[0]);
                    }}
                />
            )}

            <FeedTitle feedTitle={settings.feedTitle} onChangeFeedTitle={(feedTitle) => updateInputSettings('feedTitle', feedTitle)} />

            <FieldSetSelectInput
                label={Translation.get('dynamicLayers.labels.display', 'template-designer')}
                value={settings.display}
                options={[
                    {
                        key: 'grid',
                        value: Translation.get('dynamicLayers.labels.grid', 'template-designer')
                    },
                    {
                        key: 'list',
                        value: Translation.get('dynamicLayers.labels.list', 'template-designer')
                    }
                ]}
                onMutation={(value) => updateInputSettings('display', value)}
            />

            <FieldSetSwitch
                label={Translation.get('dynamicLayers.labels.compact', 'template-designer')}
                value={settings.display}
                onMutation={(value) => updateInputSettings('display', value)}
            />

            {Object.keys(currentFeedMapping).length === 0 ? (
                <Warning
                    label={Translation.get('dynamicLayers.labels.noMappedLayers.label', 'template-designer')}
                    action={Translation.get('dynamicLayers.labels.noMappedLayers.action', 'template-designer')}
                    onClick={() => TemplateDesignerStore.save(['view.currentView', CurrentView.FeedMapping, false])}
                />
            ) : (
                connectedDynamicLayerInputs.length === 0 && (
                    <Warning
                        label={Translation.get('dynamicLayers.labels.noMappedInputs.label', 'template-designer')}
                        action={Translation.get('dynamicLayers.labels.noMappedInputs.action', 'template-designer')}
                        onClick={() => setShowDynamicLayerDialog(true)}
                    />
                )
            )}

            {!!connectedDynamicLayerInputs.length && (
                <FieldSetSelectInput
                    value={usage}
                    label={Translation.get('dynamicLayers.labels.feedSelectorUsed', 'template-designer')}
                    onMutation={handleChangeUsage}
                    options={[
                        {
                            key: 'custom',
                            value: Translation.get('dynamicLayers.labels.feedSelectorCustom', 'template-designer')
                        },
                        {
                            key: 'locked',
                            value: Translation.get('dynamicLayers.labels.feedSelectorLocked', 'template-designer')
                        }
                    ]}
                />
            )}

            {usage === 'custom' && (
                <div className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layers">
                    {!!connectedDynamicLayerInputs.length && (
                        <span className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layers-label">
                            {Translation.get('dynamicLayers.labels.feedSelectorEdited', 'template-designer')}
                        </span>
                    )}
                    {connectedDynamicLayerInputs.map((input) => {
                        const id = 'layerKey' in input ? input.layerKey + '_' + input.attribute : input.attribute;
                        const layer = 'layerKey' in input && LayerHelpers.findLayer(input.layerKey);

                        if (!layer) return null;

                        const checked = (() => {
                            if (!input.settings.readOnly) return true;
                            return false;
                        })();

                        return (
                            <div key={id} className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer">
                                <Toggle checked={checked} onChange={(e) => DynamicLayerHelpers.setInputReadOnly(input.key, !e?.target.checked)} id={id} />
                                <label className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-settings" htmlFor={id}>
                                    <div className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-info">
                                        <Icon className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-icon">
                                            {DynamicLayerHelpers.getDynamicLayerIcon(input.attribute)}
                                        </Icon>
                                        <span className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-title">
                                            {layer.title}
                                        </span>
                                        <span className="template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-type">
                                            {layer.type} {Translation.get('general.labels.layer', 'template-designer')}
                                            {' > '}
                                            {startCase(input.attribute)}
                                        </span>
                                    </div>

                                    <span
                                        className={classNames('template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-status', {
                                            ['template-designer__dynamic-layer-edit__feed-item-selector-settings-form__layer-status--editable']: checked
                                        })}>
                                        {checked
                                            ? Translation.get('dynamicLayers.labels.editable', 'template-designer')
                                            : Translation.get('dynamicLayers.labels.readOnly', 'template-designer')}
                                    </span>
                                </label>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export { FeedItemSelectorSettingsForm };
