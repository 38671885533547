import React, { useEffect, useMemo } from 'react';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { ButtonGroupSettings, DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import Translation from 'components/data/Translation';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/main.scss';

interface Props {
    attribute?: DynamicLayerInput['attribute'];
    options: ButtonGroupFormOption[];
    updateSettings?: InputAttribute<ButtonGroupSettings>['updateSettings'];
}

export interface ButtonGroupFormOption {
    value: string;
    label: string;
    icon?: GenericSvgIcon | string;
}

const ButtonGroupForm = ({ attribute, options, updateSettings }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<ButtonGroupSettings>();

    const templateValue = useMemo(
        () => attribute && activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    );

    // Update the options if the template value changes and the template value is not in the options.
    useEffect(() => {
        if (!activeInput || !activeInputLayer || settings.type !== 'buttonGroup' || !templateValue || !attribute) return;
        if (updateSettings && !settings.options[templateValue as string]) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue]);

    if (!activeInput || settings.type !== 'buttonGroup') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            {options.map((option) => (
                <SwitchSetting
                    key={option.value}
                    label={option.label}
                    value={!!settings.options.find((settingOption) => settingOption.key === option.value)}
                    disabled={option.value === templateValue}
                    disabledTooltip={
                        option.value === templateValue
                            ? Translation.get('dynamicLayers.layerEdit.editForms.forms.general.optionDisabled', 'template-designer')
                            : undefined
                    }
                    update={(value) => {
                        let newOptions: typeof settings.options = cloneDeep(settings.options);

                        if (value) {
                            newOptions.push({ key: option.value, value: option.label });
                        } else {
                            newOptions = newOptions.filter((filterOption) => filterOption.key !== option.value);
                        }

                        // Sort object based on order of options
                        const sortedOptions: typeof settings.options = [];
                        options.forEach((option) => {
                            const newOption = newOptions.find((newOption) => newOption.key === option.value);
                            if (newOption) {
                                sortedOptions.push(newOption);
                            }
                        });

                        updateInputSettings('options', sortedOptions);
                    }}
                    icon={option.icon}
                />
            ))}
        </div>
    );
};

export { ButtonGroupForm };
