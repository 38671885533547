import React from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { HiddenSettings } from 'components/template-designer/types/dynamicLayer.type';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import '../styles/main.scss';

const ParseValueSettingsForm = (): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings } = useDynamicLayers<HiddenSettings>();

    if (!activeInput || !activeInputLayer || settings.type !== 'hidden') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form">
            <FieldSetCopy
                label={Translation.get('dynamicLayers.labels.hidden', 'template-designer')}
                value={settings.parseValue}
                useValueObject={false}
                onMutation={(value) => updateInputSettings('parseValue', value)}
            />
        </div>
    );
};

export { ParseValueSettingsForm };
