import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import Alert from 'components/ui-components-v2/Alert';
import Translation from 'components/data/Translation';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/dynamic-layers.context';
import { AlertInput, AlertSettings } from 'components/template-designer/types/dynamicLayer.type';
import User from 'components/data/User';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import '../styles/main.scss';

const CustomInputForm = (): JSX.Element | null => {
    const { settings, activeInput, updateActiveInput } = useDynamicLayers<AlertSettings>();
    const [textValue, setTextValue] = useState(JSON.stringify(settings, null, '\t'));
    const [isValidJson, setIsValidJson] = useState(true);

    useEffect(() => {
        setTextValue(JSON.stringify(settings, null, '\t'));
        setIsValidJson(true);
    }, [activeInput?.key]);

    if (!activeInput) return null;

    const onChange = (value: string) => {
        setTextValue(value);
        let json = value;
        try {
            json = JSON.parse(value);
            updateActiveInput('settings', json);
            setIsValidJson(true);
        } catch (e) {
            setIsValidJson(false);
        }
    };

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form test">
            <AceEditor
                readOnly={User.get('type') !== 'superadmin'}
                mode="html"
                width={'100%'}
                height={'300px'}
                theme="github"
                onChange={onChange}
                value={textValue}
                editorProps={{ $blockScrolling: true }}
                setOptions={{ useWorker: false }}
            />
            {!isValidJson && (
                <Alert severity="warning">{Translation.get('dynamicLayers.layerEdit.editForms.forms.customInput.invalidJson', 'template-designer')}</Alert>
            )}
        </div>
    );
};

export { CustomInputForm };
