import React, { createContext, useState, useMemo } from 'react';
import { DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import Layer from 'components/template-designer/types/layer.type';
import { useDynamicLayers } from './use-dynamic-layers';
import { formatData } from '../../dynamic-layer-input-dialog';

interface Props {
    children: React.ReactNode;
}

export interface DynamicLayersContextType {
    activeInput: DynamicLayerInput | null;
    setActiveInput: React.Dispatch<React.SetStateAction<DynamicLayerInput | null>>;
    activeInputLayer: Layer | null;
}
// Create a context.
export const DynamicLayersContext = createContext<DynamicLayersContextType | undefined>(undefined);

/**
 * Provider for the dynamic layers context.
 * This provider is responsible for managing the active input.
 */
const DynamicLayersProvider = ({ children }: Props) => {
    const [activeInput, setActiveInput] = useState<DynamicLayerInput | null>(null);

    /**
     * Get the layer that is connected to the active input.
     */
    const activeInputLayer = useMemo(() => {
        if (!activeInput || !('layerKey' in activeInput)) return null;
        if (activeInput.layerKey === 'formatData') {
            return formatData as Layer;
        }
        return LayerHelpers.findLayer(activeInput.layerKey);
    }, [activeInput?.key]);

    return <DynamicLayersContext.Provider value={{ activeInput, setActiveInput, activeInputLayer }}>{children}</DynamicLayersContext.Provider>;
};

export { DynamicLayersProvider, useDynamicLayers };
