import React, { useEffect, useMemo } from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { DynamicLayerInput, RadioListSettings, SelectSettings, TextSelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import { FieldSetRepeater } from 'components/input/Repeater';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { FieldSetNumber } from 'components/input/Number';
import { SwitchSetting } from '../inputs/switch-setting';
import { SettingsHelpers } from '../helpers/settings.helpers';
import '../styles/main.scss';

interface Props {
    unit?: '%' | 'deg' | 'px' | 'em' | 'ms';
    inputType: 'text' | 'number';
    attribute: DynamicLayerInput['attribute'];
    updateSettings?: InputAttribute<RadioListSettings | SelectSettings | TextSelectSettings>['updateSettings'];
    disabledIndex?: number;
    multiline?: boolean;
    defaultCharacterLimit?: number;
}

/**
 * Component to change the settings of the select and radioList with dynamic changeable options
 */
const MultiSelectFormDynamic = ({ unit, inputType, attribute, updateSettings, disabledIndex, multiline, defaultCharacterLimit }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<
        SelectSettings | RadioListSettings | TextSelectSettings
    >();

    const templateValue = useMemo(
        () => activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    );

    /**
     * Sort the options by key if they are numbers, keeping the first option in place.
     */
    const sortOptions = () => {
        if (!settings.options || settings.options.length < 2) return;
        const [firstOption, ...restOptions] = settings.options;
        const sortedOptions = restOptions.sort((a, b) => {
            if (Number(a.key) < Number(b.key)) return -1;
            if (Number(a.key) > Number(b.key)) return 1;
            return 0;
        });
        const newOptions = [firstOption, ...sortedOptions];
        updateInputSettings('options', newOptions);
    };

    /**
     * Sort the options when the input type is number.
     */
    useEffect(() => {
        if (inputType === 'number') sortOptions();
    }, []);

    /**
     * Update the first value if the template color value changes.
     */
    useEffect(() => {
        if (!activeInput || !activeInputLayer) return;
        if (updateSettings && settings.options && settings.options[0].value !== templateValue) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue, settings.type]);

    if (
        !activeInput ||
        (settings.type !== 'select' &&
            settings.type !== 'selectMultiLanguage' &&
            settings.type !== 'radioList' &&
            settings.type !== 'textSelect' &&
            settings.type !== 'textSelectMultiLanguage' &&
            settings.type !== 'radioListMultiLanguage')
    )
        return null;

    if (!activeInput) return null;

    const activeInputConfig = SettingsHelpers.getActiveInputConfig(activeInputLayer, activeInput);

    const attributes = activeInputConfig?.settings;

    return (
        <>
            <div className="template-designer__dynamic-layer-edit__settings-form template-designer__dynamic-layer-edit__multi-select-form">
                {attributes && 'radioList' in attributes && 'select' in attributes && (
                    <ToggleButtonGroup
                        value={(() => {
                            if ('selectType' in settings) {
                                return settings.selectType;
                            }
                            return settings.type.replace('MultiLanguage', '');
                        })()}
                        size="small"
                        color="primary"
                        onChange={(_, type) => {
                            if (!type || type === settings.type) return;
                            if (
                                settings.type === 'select' ||
                                settings.type === 'selectMultiLanguage' ||
                                settings.type === 'radioList' ||
                                settings.type === 'radioListMultiLanguage'
                            ) {
                                const newSettings = activeInputConfig?.settings?.[type];
                                if (newSettings) {
                                    updateActiveInput('settings', {
                                        ...newSettings,
                                        options: settings.options,
                                        label: activeInput.label,
                                        languageSpecific: 'languageSpecific' in activeInput && activeInput.languageSpecific
                                    });
                                }
                            } else {
                                updateActiveInput('settings', { ...settings, selectType: type });
                            }
                        }}
                        exclusive>
                        <ToggleButton value="radioList">
                            {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.radioList', 'template-designer')}
                        </ToggleButton>
                        <ToggleButton value="select">
                            {Translation.get('dynamicLayers.layerEdit.editForms.inputTypes.select', 'template-designer')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
                <FieldSetRepeater
                    key={settings.type}
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.multiSelect.options', 'template-designer')}
                    value={settings.options.map((option) => option.value)}
                    disabledIndex={disabledIndex}
                    disabledIndexTooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.general.optionDisabled', 'template-designer')}
                    inputType={inputType}
                    fullWidth
                    onBlur={inputType === 'number' && sortOptions}
                    inputProps={{ endAdornment: unit, useValueObject: false, multiline: multiline }}
                    addLabel={Translation.get('dynamicLayers.layerEdit.editForms.forms.multiSelect.addOption', 'template-designer')}
                    onMutation={(values) => {
                        const newOptions = values.map((key) => {
                            return {
                                key: key || (inputType === 'number' ? '0' : ''),
                                value: key || (inputType === 'number' ? '0' : '')
                            };
                        });
                        updateInputSettings('options', newOptions);
                    }}
                />

                {(settings.type === 'textSelect' || settings.type === 'textSelectMultiLanguage') && (
                    <>
                        <SwitchSetting
                            label={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.useCharacterLimit', 'template-designer')}
                            value={!!settings['maxLength']}
                            update={(value) => {
                                if (value) {
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    updateInputSettings('maxLength' as any, defaultCharacterLimit);
                                } else {
                                    const newSettings = cloneDeep(settings);
                                    delete newSettings.maxLength;
                                    updateActiveInput('settings', {
                                        ...newSettings
                                    });
                                }
                            }}
                        />
                        {!!settings['maxLength'] && (
                            <FieldSetNumber
                                label={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.characterLimit', 'template-designer')}
                                tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.textSettings.characterLimitTooltip', 'template-designer')}
                                value={settings.maxLength}
                                min={1}
                                max={1000}
                                onMutation={(newValue) => {
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    updateInputSettings('maxLength' as any, parseInt(newValue));
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export { MultiSelectFormDynamic };
