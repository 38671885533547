import Layer from 'components/template-designer/types/layer.type';
import { DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { attributeInputs } from '../../../config/attribute-inputs';

class SettingsHelpers {
    /**
     * Get the active input config.
     * @param activeInputLayer - The active input layer.
     * @param activeInput - The active input.
     * @returns The active input config.
     */
    static getActiveInputConfig = (activeInputLayer: Layer | null, activeInput?: DynamicLayerInput): InputAttribute<unknown> | undefined => {
        if (!activeInput) return;

        const attributeInputType = activeInputLayer?.type;

        const activeInputConfig =
            (attributeInputType && attributeInputs[attributeInputType]?.all[activeInput.attribute]) ||
            (attributeInputType && attributeInputs[attributeInputType]?.superAdmin?.[activeInput.attribute]) ||
            attributeInputs['format']?.all[activeInput.attribute] ||
            attributeInputs['format']?.superAdmin?.[activeInput.attribute] ||
            attributeInputs['other']?.all[activeInput.attribute] ||
            attributeInputs['other']?.superAdmin?.[activeInput.attribute];

        return activeInputConfig;
    };
}

export { SettingsHelpers };
