import React from 'react';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { FieldSetNumber } from 'components/input/Number';
import Translation from 'components/data/Translation';
import { FieldSet } from 'components/input/FieldSet';
import { SwitchSetting } from '../inputs/switch-setting';
import '../styles/main.scss';

interface Props {
    unit?: '%' | 'deg' | 'px' | 'em' | 'ms';
    allowNegative?: boolean;
}

const NumberSettingsForm = ({ unit, allowNegative }: Props): JSX.Element | null => {
    const { settings, activeInput, updateInputSettings } = useDynamicLayers<SliderSettings>();

    if (!activeInput || (settings.type !== 'slider' && settings.type !== 'number')) return null;

    return (
        <div className="template-designer__dynamic-layer-edit__slider-settings-form template-designer__dynamic-layer-edit__settings-form">
            <div className="template-designer__dynamic-layer-edit__slider-settings-form__boundaries">
                <FieldSet
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.min', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.numberSettings.minTooltip', 'template-designer')}>
                    <FieldSetNumber
                        min={allowNegative ? undefined : 0}
                        value={settings.min}
                        onMutation={(newValue) => updateInputSettings('min', parseFloat(newValue))}
                        endAdornment={unit}
                    />
                </FieldSet>
                <FieldSet
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.max', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.numberSettings.maxTooltip', 'template-designer')}>
                    <FieldSetNumber
                        value={settings.max}
                        onMutation={(newValue) => updateInputSettings('max', parseFloat(newValue))}
                        endAdornment={unit}
                        min={allowNegative ? undefined : 1}
                    />
                </FieldSet>
                <FieldSet
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.stepCount', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.numberSettings.stepCountTooltip', 'template-designer')}>
                    <FieldSetNumber
                        value={settings.step}
                        onMutation={(newValue) => updateInputSettings('step', parseInt(newValue))}
                        step={1}
                        min={1}
                        endAdornment={unit}
                    />
                </FieldSet>
            </div>
            {settings.type === 'slider' && (
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.showNumberInput', 'template-designer')}
                    value={settings.inputFieldDisplay}
                    update={(newValue) => updateInputSettings('inputFieldDisplay', newValue)}
                />
            )}
            {settings.type === 'slider' && (
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.slider.showStep', 'template-designer')}
                    value={settings.marks}
                    update={(newValue) => updateInputSettings('marks', newValue)}
                />
            )}
        </div>
    );
};

export { NumberSettingsForm };
